<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      <el-breadcrumb-item>员工库管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="oraRbox">
        <div class="orabox-left">
          <zm-tree-org
            :data="datas"
            collapsable
            @on-node-click="nodeClick"
            @on-contextmenu="onCont"
            :define-menus="define"
            :node-draggable="draggable"
            :props="ZMprops"
            :default-expand-level="1"
          ></zm-tree-org>
          <div class="hinttitle-right">
            <el-button type="primary" class="verbal brns"></el-button>
            <div class="verbal">组织</div>
            <el-button type="success" class="verbal brns"></el-button>
            <div class="verbal">岗位</div>
          </div>
        </div>
        <div class="orabox-right">
          <!-- 组织模块 -->
          <div class="right-org">
            <div class="righttop">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item class="texts" v-for="(item, index) in this.Itemlink" :key="index">{{ item.name }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="btns">
              <el-button type="primary" plain @click="Roleclick" v-if="this.container_status === 1">员工库</el-button>
            </div>
            <el-table :data="posttableData" style="width: 90%" max-height="500" class="ta">
              <el-table-column prop="work_no" label="工号" width="100" align="center"></el-table-column>
              <el-table-column prop="user_name" label="姓名" width="120" align="center"></el-table-column>
              <el-table-column prop="type_name" label="职级" align="center"></el-table-column>
              <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 员工库绑定弹窗 -->
    <Role :roleVisible="roleVisible" :rolename="rolename" :roleId="roleId" @roleClose="roleClose" @Emits="Emits"></Role>
  </div>
</template>

<script>
import { Organtreenode, Nodeuserlist } from '../../../api/personnel.js'
import Fenye from '../../../components/Fenye.vue'
import Role from './components/role.vue'
export default {
  components: {
    Role,
    Fenye
  },
  data() {
    return {
      roleVisible: false,
      datas: {},
      posttableData: [],
      rolename: '',
      container_status: '',
      define: [{ name: '复制文本', command: 'copy' }],
      draggable: false,
      ZMprops: { id: 'id', label: 'name' },
      roleId: null,
      Itemlink: [],
      pageData: {
        page: 1,
        size: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.Organtrees()
  },
  methods: {
    // 岗位按钮
    Roleclick() {
      this.roleVisible = true
    },

    // 节点点击
    nodeClick(e, data) {
    //   console.log(data)
      if (data.type === 'node') {
        this.container_status = data.container_status
        this.title = '组织'
        this.Itemlink = data.link
        let arr = data.link
        let userNames = arr
          .map(item => {
            return item.name
          })
          .join('>')
        // console.log(userNames)
        this.rolename = userNames
        this.roleId = Number(data.id)
        this.Nodeuserlists(data.node_id)
      }
    },

    // 右键
    onCont(command) {
      // console.log(command)
    },

    // 岗位添加弹窗关闭
    roleClose() {
      this.roleVisible = false
    },

    Emits(e) {
    //   console.log('触发了', e)
      this.Nodeuserlists(e)
    },

    ///////////////////请求区//////////////////

    // 获取组织树
    async Organtrees() {
      const { data } = await Organtreenode()
      // console.log(data.data)
      if (data.data.length === 0) {
        this.datas = {}
        this.disabled = true
      } else {
        this.datas = data.data
        let Objs = data.data
        this.disabled = false
        this.getChild(Objs, '数据')
      }
    },

    async Nodeuserlists(node_id) {
      const { data } = await Nodeuserlist({ node_id: node_id })
    //   console.log(data)
      this.posttableData = data.data
    },

    // 递归
    getChild(valueArr, type) {
      //   console.log(valueArr, type)
      let level = 0
      let maxLevel = 0
      const loop = (data, level) => {
        // console.log(data, 'data')
        data.children.forEach(item => {
          item.level = level
          if (level > maxLevel) {
            maxLevel = level
          }
          // console.log(item)
          if ('children' in item) {
            if (item.type === 'node') {
              item.style = { backgroundColor: '#409EFF', color: 'white', borderRadius: ' 9px' , maxHeight: '50px'}
            } else {
              item.style = { backgroundColor: '#85CE61', color: 'white', borderRadius: ' 9px' , maxHeight: '50px'}
            }
            if (item.children.length > 0) {
              loop(item, level + 1)
            }
            if (item.children.length == 0) {
              item.children = null
            }
          }
        })
      }
      loop(valueArr, 1)
    }
  }
}
</script>

<style  lang="less">
.oraRbox {
  box-sizing: border-box;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: space-around;
  .orabox-left {
    position: relative;
    width: 68%;
    height: 100%;
    background-color: #f2f2f2;
    .hinttitle-right {
      display: flex;
      align-items: center;
      width: 23%;
      position: absolute;
      top: 0%;
      right: 0%;
      .verbal {
        margin-left: 2%;
      }
      .brns {
        margin-left: 5%;
      }
    }
  }
  .orabox-right {
    width: 32%;
    height: 100%;
    border: 1px solid #dddddd !important;
    box-shadow: 2px 2px 5px 2px #dddddd;
    .texts {
      font-size: 16px !important;
      color: #000 !important;
    }
    .righttop {
      width: 100%;
      height: 5vh;
      box-sizing: border-box;
      // border: 1px solid #000;
      padding: 7px 10px;
    }
    .right-org {
      box-sizing: border-box;
      padding: 5px 10px;
      // border: 1px solid #000;
      width: 100%;
      height: 100%;
      .btns {
        width: 100%;
        height: 6vh;
      }
    }
  }
}
.tree-org-node__text {
  padding: 0px 15px !important;
}
.tree-org-node__content {
  background-color: #409eff;
  color: white;
  border-radius: 9px !important;
}
.NoOk {
  margin-left: 10%;
}
.dialogOrg {
  width: 100%;
  // border: 1px solid #000;
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  .dialogOrg-left {
    width: 48%;
  }
  .dialogOrg-right {
    width: 48%;
    .shai {
      width: 100%;
      // border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
</style>